<template>
  <div class="login">login</div>
</template>

<script>
  export default {
    name: 'default.vue',
    components: {},
    created() {}
  };
</script>

<style lang="less" scoped>
  .login {
    width: 100vw;
    height: calc(100vh - 65px);
    background-color: #efefef;
  }
</style>
